import Image from "next/image";
import { useState, FunctionComponent } from "react";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import basePath from "lib/helpers/basePath";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  question: string;
  answer: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(1),
      color: "#fff",
      backgroundColor: "#1B1D6A",
      border: "none",
      boxShadow: "none",
    },
  })
);

const FAQComponent: FunctionComponent<Props> = ({
  question = "",
  answer = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-gradient my-[5px] py-[5px] md:py-[10px] px-[12px] md:px-[40px] cursor-pointer text-[#fff] text-[17px] rounded"
      >
        <div className="flex justify-between items-center py-[8px]">
          <h2
            className="w-9/12 text-[15px] md:text-[17px] leading-[21px]"
            dangerouslySetInnerHTML={{ __html: question }}
          />
          <div
            className={`animate-rotate flex items-center ${
              isOpen ? "rotate-[135deg]" : ""
            }`}
          >
            <Image
              src={`${basePath}/assets/icons/plus-icon.svg`}
              width={40}
              height={40}
              alt="Plus Icon"
            />
          </div>
        </div>
      </div>
      <Collapse in={isOpen} timeout={1000} style={{ transformOrigin: "0 0 0" }}>
        <Paper elevation={4} className={classes.paper}>
          <div
            className="text-[#c8c9db] text-[15px] md:text-[18px] md:px-[40px] my-4 ease-in-out duration-300"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </Paper>
      </Collapse>
      <style jsx>
        {`
          .animate-rotate {
            animation: rotate 5s linear infinite;
            transition-duration: 1s;
          }
        `}
      </style>
    </>
  );
};

export default FAQComponent;
