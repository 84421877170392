import FAQComponent from "components/common/Faqs";

interface Props {
  faq: Array<Record<string, string>>;
}
function Faqs({ faq }: Props) {
  return (
    <section className="py-[40px] px-[16px] md:py-[60px] bg-[#1B1D6A]">
      <h2 className="font-bold text-[28px] md:text-[36px] leading-[36px] md:leading-[42px] md:px-8 md:text-center text-[#fff] mb-[38px] md:mb-[40px]">
        Frequently asked questions
      </h2>
      {faq?.map((element, index) => <div key={`faqs-${index}`} className="md:px-[15%]"><FAQComponent answer={element.answer} question={element.question} /></div>)}
    </section>
  );
}

export default Faqs;
